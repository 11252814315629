<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-card>
            <v-form ref="form" v-model="formValid">
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}} 
                </v-card-title>

                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    <v-select
                        v-model="vals.sdg"
                        :rules="[value => value>=0 || 'Requerido.']"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.sdg"
                        label="Edad (SDG)"
                    />

                    <v-select
                        v-model="vals.llanto"
                        :rules="[value => value>=-2 || 'Requerido.']"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.llanto"
                        label="Llanto e irritabilidad"
                    />

                    <v-select
                        v-model="vals.comportamiento"
                        :rules="[value => value>=-2 || 'Requerido.']"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.comportamiento"
                        label="Comportamiento"
                    />

                    <v-select
                        v-model="vals.facial"
                        :rules="[value => value>=-2 || 'Requerido.']"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.facial"
                        label="Expresión facial"
                    />

                    <v-select
                        v-model="vals.muscular"
                        :rules="[value => value>=-2 || 'Requerido.']"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.muscular"
                        label="Tono muscular de extremidades"
                    />

                    <v-select
                        v-model="vals.signos"
                        :rules="[value => value>=-2 || 'Requerido.']"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.signos"
                        label="Signos vitales"
                    />

                    <v-text-field
                        v-model="observaciones"
                        
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>

                    <v-expand-transition>
                        <div v-if="sumaValoracion" class="text-center">
                            <div class="title">Puntaje: {{sumaValoracion}}</div>
                            <!--
                            <div>{{textoValoracion}}</div>
                            -->
                        </div>
                    </v-expand-transition>

                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        color="success"
                        :loading="loading"
                        :disabled="!formValid"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
        fechaNacimiento:String,
    },

    data:()=>({
        loading:false,
        observaciones:'',
        formValid:false,
        vals:{},
        itemsEscala:{
            llanto:[
                {value:-2, text: '(-2) No llora con estímulos dolorosos'},
                {value:-1, text: '(-1) Gime o llora con pocos estímulos dolorosos'},
                {value:0, text: '(0) Llanto regular. No irritable'},
                {value:1, text: '(1) Irritable o ataques de llanto. Consolable'},
                {value:2, text: '(2) Llanto continuo, Incinsolable'},
            ],
            comportamiento:[
                {value:-2, text: '(-2) No reacciona a estímulos. Inmovil'},
                {value:-1, text: '(-1) Reacciona poco a estimulos. Poco movimiento'},
                {value:0, text: '(0) Normal para la edad gestacional'},
                {value:1, text: '(1) Inquieto, se retuerce, se despierta seguido'},
                {value:2, text: '(2) Se arquea o patea, no se mueve, sedado'},
            ],
            facial:[
                {value:-2, text: '(-2) Boca relajada sin expresión'},
                {value:-1, text: '(-1) Poca expresión con estímulos'},
                {value:0, text: '(0) Relajado'},
                {value:1, text: '(1) Demuestra dolor esporádicamente'},
                {value:2, text: '(2) Demuestra dolor continuamente'},
            ],
            muscular:[
                {value:-2, text: '(-2) Sin reflejo de agarre, flácido'},
                {value:-1, text: '(-1) Reflejo de agarre debil. Poco tono muscular'},
                {value:0, text: '(0) Tono normal. Manos y pies relajados'},
                {value:1, text: '(1) Demuestra dolor esporádicamente'},
                {value:2, text: '(2) Demuestra dolor continuamente'},
            ],
            signos:[
                {value:-2, text: '(-2) Sin cambio con estímulos. Hipoventila o apnea'},
                {value:-1, text: '(-1) Varía menos del 10% de valores iniciales con estímulos'},
                {value:0, text: '(0) Valores normales para la edad'},
                {value:1, text: '(1) Aumenta 10 a 20% de valores. Con estímulos aumento rápido SaO2 76-85%'},
                {value:2, text: '(2) Aumento mayor a 20% de valores. Con estímulos aumento lento. SaO2 <=75%, resiste ventilador'},
            ],
            sdg:[
                {value:1, text: '(1) De 32 a 35 SDG'},
                {value:2, text: '(2) De 28 a 31 SDG'},
                {value:3, text: '(3) Menos de 28 SDG'},
            ],
            
        },
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                //console.log(val)
                this.$refs.form.reset()
                this.$emit('update:dialogDe',val)
            },
        },
        sumaValoracion(){
            return false
            //if (this.vals.ocular>0 && this.vals.verbal>0 &&  this.vals.motor>0){
            //    return (parseInt(this.vals.ocular) + parseInt(this.vals.verbal) + parseInt(this.vals.motor))
            //} else {
            //    return null
            //}
        },
        textoValoracion(){
            if (this.sumaValoracion){
                let text = ''
                if (this.sumaValoracion <= 8)
                    text = 'Trauma severo'
                if (this.sumaValoracion > 8 && this.sumaValoracion <= 12)
                    text = 'Trauma moderado'
                if (this.sumaValoracion > 12)
                    text = 'Trauma Leve'
                return text
            } else {
                return null
            }
        }
    },
    methods:{
        cancela(){
            //this.vals.ocular = this.vals.verbal = this.vals.motor = 0
            this.dialog = false
            this.observaciones = ''
        },
        async agrega(){

            this.loading=true
            //this.vals.correccionEdad = this.correccionEdad
            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'npass',
                valor: JSON.stringify(this.vals),
                internamiento:this.internamiento,
                observaciones: this.observaciones
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    //this.vals.ocular = this.vals.verbal = this.vals.motor = 0
                    this.observaciones = ''
                    this.loading = false
                    this.dialog = false

                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
            
        },
    },
    
}
</script>

<style>

</style>